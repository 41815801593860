import utilsService from '@/services/utils/utils.service'
import localstorageService from '@/services/localstorage/localstorage.service'

const { isAdminEtablissement, isAgentEtablissement, etablissementDetails } = utilsService.currentUserUtils()
const etablissementId = etablissementDetails && etablissementDetails._id || ''
const isEtablissementUser = isAdminEtablissement || isAgentEtablissement
const userId = localstorageService.getUserId()
const forms = utilsService.getListFormulaires()

export default [{
  title: 'Établissement(s)',
  icon: 'BookIcon',
  action: 'read',
  resource: 'Public',
  children: [{
    title: 'Liste des établissements',
    icon: 'ListIcon',
    route: {
      name: 'etablissements-list',
      // params: { etablissementId },
    },
    action: 'read',
    resource: 'etablissement',
  },
  {
    ...(isEtablissementUser
    && {
      title: 'Mon établissement',
      icon: 'ListIcon',
      route: {
        name: 'etablissements-details',
        params: { etablissementId },
      },
      action: 'read',
      resource: 'etablissementDetails',
    }),
  },
  // {
  //   title: 'Ajouter un établissement',
  //   icon: 'PlusIcon',
  //   route: {
  //     name: 'etablissements-create',
  //     params: { etablissementId: userId },
  //   },
  //   action: 'create',
  //   resource: 'etablissement',
  // },
  {
    title: 'Agents',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'agentEtablissement',
    children: [{
      title: 'Liste des agents',
      icon: 'ListIcon',
      route: {
        name: 'etablissements-agents-list',
        params: { etablissementId },
      },
      action: 'read',
      resource: 'agentEtablissement',
    },
    {
      title: 'Ajouter un agent',
      icon: 'PlusIcon',
      route: {
        name: 'etablissements-agents-create',
        params: { etablissementId },
      },
      action: 'create',
      resource: 'agentEtablissement',
    },
    ],
  },
  ],
}]
