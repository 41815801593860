/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/

import utilsService from '@/services/utils/utils.service'
import dashboard from './dashboard'
import formulaires from './formulaires'
import configurations from './configurations'
import statistics from './statistics'
import etablissements from './etablissements'
// import agents from './agents'
import commissaire from './commissaire'
import comptesUtilisateurs from './comptesUtilisateurs'
// import fichesRemplies from './fichesRemplies'
import faq from './faq'

const filterRoute = routes => (utilsService.isSuperAdmin ? routes : [])

// Array of sections
export default [
  ...dashboard,
  // ...users,

  // ...filterRoute(formulaires),
  ...formulaires,
  ...etablissements,
  // ...agents,
  ...commissaire,
  ...comptesUtilisateurs,
  ...statistics, 
  ...faq,
  // ...fichesRemplies,
  ...configurations,

]
