import utilsService from '@/services/utils/utils.service'

const { isAdminEtablissement, isAgentEtablissement, etablissementDetails } = utilsService.currentUserUtils()
const etablissementId = etablissementDetails && etablissementDetails._id || ''
const isEtablissementUser = isAdminEtablissement || isAgentEtablissement
const forms = utilsService.getListFormulaires()
export default [
  {
    title: 'Statistiques',
    icon: 'BookIcon',
    resource: 'statsTest',
    action: 'read',
    children: [
      // {
      //   title: isEtablissementUser
      //     ? "Statistiques sur l'exploitation de mon établissement"
      //     : "Statistiques sur l'exploitation des établissements toutistiques",
      //   icon: 'ActivityIcon',
      //   route: {
      //     name: 'statistiques-etablissements',
      //   },
      //   action: 'read',
      //   resource: 'statsTest',
      // },
      // Statistiques sur les agences de transport
      {
        title: 'Statistiques sur les agences de transport',
        icon: 'ActivityIcon',
        route: {
          name: 'stats-agences-transport',
        },
        action: 'read',
        resource: 'statsTest',
      },
      // Statistiques sur les frontières terrestres
      {
        title: 'Statistiques sur les frontières terrestres',
        icon: 'ActivityIcon',
        route: {
          name: 'stats-frontieres-terrestres',
        },
        action: 'read',
        resource: 'statsTest',
      },
      //  Statistique sur les agences de location
      {
        title: 'Statistiques sur les agences de location sans opérateur',
        icon: 'ActivityIcon',
        route: {
          name: 'stats-agences-location-sans-operateur',
        },
        action: 'read',
        resource: 'statsTest',
      },
      // Statistique sur les établissements de restauration
      {
        title: 'Statistiques sur les établissements de restauration',
        icon: 'ActivityIcon',
        route: {
          name: 'stats-etablissements-restauration',
        },
        action: 'read',
        resource: 'statsTest',
      },
      // Statistiques sur les industries touristiques
      {
        title: 'Statistiques sur les industries touristiques',
        icon: 'ActivityIcon',
        route: {
          name: 'stats-industries-touristiques',
        },
        action: 'read',
        resource: 'statsTest',
      },
      // Statistiques sur les agences de voyage
      {
        title: 'Statistiques sur les agences de voyage',
        icon: 'ActivityIcon',
        route: {
          name: 'stats-agences-voyage',
        },
        action: 'read',
        resource: 'statsTest',
      },
      // Statistique sur exploitation des hotels
      {
        title: 'Statistiques sur l\'exploitation des établissements d’hébergement',
        icon: 'ActivityIcon',
        route: {
          name: 'stats-etablissement-hebergement',
        },
        action: 'read',
        resource: 'statsTest',
      },
      // {
      //   title: 'Statistiques sur l\'exploitation des agences de voyage',
      //   icon: 'ActivityIcon',
      //   route: {
      //     name: 'stats-agence-voyage',
      //   },
      //   action: 'read',
      //   resource: 'statsTest',
      // },

      // {
      //   title: 'Statistiques aéroport',
      //   icon: 'ActivityIcon',
      //   route: {
      //     name: 'statistiques-aeroport',
      //   },
      //   action: 'read',
      //   resource: 'Public',
      // },
    ],
  },

]
