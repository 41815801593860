<template>
  <p class="clearfix mb-0 mt-1 text-center">
    <!-- <span class="float-md-left">
      <b-link
        href="/"
        target="_blank"
      >Requêtes</b-link>
      <b-link
        class="ml-2"
        href="/"
        target="_blank"
      >Contacts</b-link>
    </span> -->
    <span class="d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="/" target="_blank">{{appName}}</b-link>
      <!-- <span class="d-none d-sm-inline-block">, All rights Reserved</span> -->
    </span>

    <!--
       <span
      v-if="userDatas && false"
      class="float-md-right d-none d-md-block"
    >
      Compagnie, {{ userDatas.fullName }}, {{ datetime }}
    </span> -->
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";
import { setInterval } from "timers";
import { $themeConfig } from '@themeConfig'
import localstorageService from '@/services/localstorage/localstorage.service'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      datetime: "",
      userDatas: null,
    };
  },
   setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  mounted() {
    if (localStorage.getItem("userData")) {
      this.userDatas = localstorageService.getUserData();
    }
    setInterval(() => {
      const d = new Date();
      this.datetime = `${[d.getDate(), d.getMonth() + 1, d.getFullYear()].join(
        "/"
      )} ${[d.getHours(), d.getMinutes(), d.getSeconds()].join(":")}`;
    }, 1000);
  },
  methods: {
    // loadDate() {
    //   // this.datetime = new Date().toLocaleString()
    //   let d = new Date;
    //   this.datetime = [d.getDate(),d.getMonth()+1,d.getFullYear()].join('/')+' '+
    //           [d.getHours(),
    //            d.getMinutes(),
    //            d.getSeconds()].join(':');
    //   console.log(this.datetime)
    // },
  },
};
</script>
